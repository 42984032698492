/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

body.light .mat-simple-snackbar-action,
body .light .mat-simple-snackbar-action {
  color: var(--fuse-text-secondary);
}

.logo {
  font-size: 72px;
  font-family: cursive;
}

.bg-theme {
  background-color: var(--fuse-primary) !important;
}

.text-theme {
  color: var(--fuse-primary) !important;
}

.border-theme {
  border-color: var(--fuse-primary) !important;
}

.float-none {
  float: none !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

mat-radio-button.mat-radio-button {
  margin-right: 16px;
}

mat-error {
  font-size: 11px;
}

mat-radio-group {
  padding-top: 20px;
}

body .mat-tab-group.fuse-mat-with-header .mat-tab-header {
  height: 100% !important;
  max-height: 100% !important;
  border: none !important;
  visibility: visible !important;
  opacity: 1 !important;
}

body .table-container {
  width: 100%;
  height: calc(100vh - 370px);
  overflow-y: auto;
}

@media (max-width: 768px) {
  body .table-container {
    width: 100%;
    height: calc(100vh - 420px);
    overflow-y: auto;
  }
}

.snackbar {
  white-space: pre-wrap;
}

:host::ng-deep.mat-checkbox-layout {
  white-space: normal !important;
}
.select-option-panel {
  margin-top: 30px !important;
}

.skeleton-loader {
  .item-wrap {
    display: flex;
    .item-content {
      margin-left: 10px;
      width: calc(100% - 130px);
    }
  }
  .no-space {
    ngx-skeleton-loader span.loader.progress {
      margin: 0;
    }
  }
}

mat-dialog-container.mat-dialog-container {
  padding: 0;
  .mat-dialog-content {
    margin: 0;
  }
  .mat-dialog-actions {
    margin-bottom: 0;
  }
}

mat-accordion.mat-accordion .mat-expansion-panel:last-child {
  margin-bottom: 24px;
}
.theme-card {
  background-color: rgba(var(--fuse-bg-card-rgb), var(--tw-bg-opacity)) !important;
  border-width: 1px !important;
  border-top: 2px solid var(--fuse-primary) !important;
}

.google-viewer-popup-overlay {
  width: 44px !important;
  height: 44px !important;
  right: 10px !important;
  top: 10px !important;
  position: absolute !important;
  z-index: 1000 !important;
}

.w-80vh {
  width: 80vw !important;
}

table {
  .banner-img-column {
    width: 100px;
  }
  .banner-img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
}

.mat-paginator .mat-paginator-container {
  justify-content: end !important;
}

/* Responsive table */
.responsive-grid-table {
  @media (max-width: 600px) {
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border-bottom-width: 1px !important;
      padding: 5px 0;

      td[data-title],
      th[data-title] {
        position: relative;
        padding: 2px 0;
        padding-left: 50%;
        white-space: pre-wrap !important;
        text-align: left;
        min-height: 25px;
      }
      td[data-title]:before,
      th[data-title]:before {
        position: absolute;
        left: 1rem;
        width: 45%;
        padding-right: 1rem;
        white-space: nowrap;
        text-align: left;
        font-weight: bold;
        content: attr(data-title);
      }
    }

    table,
    thead,
    tbody,
    tfoot,
    th,
    td,
    tr {
      display: block;
    }
  }
}
/* Responsive table End */

.arrow_icon {
  font-size: 50px;
  cursor: pointer;
}

.text-danger {
  color: red;
  font-size: 13px;
  font-weight: 600;
}

mat-checkbox.mat-mdc-checkbox.label-only {
  .mdc-checkbox {
    display: none;
  }
  .mdc-form-field {
    color: inherit !important;
  }
}
body .mat-mdc-checkbox .overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

#zmmtg-root {
  display: none;
}

.right-popup {
  position: fixed !important;
  top: 64px !important;
  right: 0px !important;
  width: 100% !important;
  height: 100% !important;
  padding: 1.25rem !important;
  max-width: 40rem !important;
  z-index: 20 !important;
  border-width: 1px !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;

  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;

  .container {
    height: calc(100% - 200px);
    margin: 50px auto;
    left: 10px;
    right: 10px;
    flex: auto;
    flex-wrap: wrap;
    overflow-y: auto;
    position: absolute;
  }
}

modern-layout .right-popup {
  top: 80px !important;
}

.cdk-overlay-container .cdk-overlay-pane {
  min-width: fit-content !important;
}
